var breakpoints = {
  max: 1439,
  large: 1199,
  medium: 991,
  small: 767,
  xsmall: 414
};

$(document).ready(function() {

  $(document).on("keypress", "#zipCode", function (e) {
    var charCode = e.which;
    var charStr = String.fromCharCode(charCode);
    if (!$.isNumeric(charStr) && ![0, 8, 9, 13, 27, null].includes(charCode)) e.preventDefault();
  });

  $(document).on("change", "#zipCode", function () {
    $(this).closest(".form-element").removeClass("show-error");
    $(this).attr("aria-invalid", "false");
  });

  $(".initial-zipcode").submit(function (e) {
    e.preventDefault();
    var $zipCodeField = $("#zipCode");
    var zipCodeValue = $zipCodeField.val();

    if (/\d{5}/.test(zipCodeValue)) {
      window.eventQueue = window.eventQueue || [];
      window.eventQueue.push({
        "eventType": "zipFormSubmitted",
        "eventData": {
          "zip": zipCodeValue
        }
      });
      window.setTimeout(function () {
        window.location.href = getRedirectURL(zipCodeValue);
      }, 250);
    }else {
      $zipCodeField.closest(".form-element").addClass("show-error");
      $zipCodeField.attr("aria-invalid", "true");
    }
  });
});

function getRedirectURL(zip) {
  // Configure params for redirectUrl
  var leadHostname = "http://leads.goodtogoquote.com/switch/web_route";
  var sourceMedium = encodeURIComponent(getURLParameter("utm_source") + " / " + getURLParameter("utm_medium"));
  var GAVisitorID = encodeURIComponent(getGAVisitorId());
  var utmCampaign = encodeURIComponent(getURLParameter("utm_campaign"));
  var utmContent = encodeURIComponent(getURLParameter("utm_content"));
  var tid = encodeURIComponent(getURLParameter("tid"));
  var gclid = encodeURIComponent(getURLParameter("gclid"));

  // Create Final Redirect URL
  var redirectUrl = leadHostname + "?zipcode=" + zip + "&source_medium=" + sourceMedium + "&ga_visitor_id=" + GAVisitorID + "&_ga=" + GAVisitorID + "&agent_code=900056";
  if (utmCampaign) redirectUrl += "&utm_campaign=" + utmCampaign;
  if (utmContent) redirectUrl += "&utm_content=" + utmContent;
  if (tid) redirectUrl += "&tid=" + tid;
  if (gclid) redirectUrl += "&eq-gclid=" + gclid;

  return redirectUrl;
}

function getURLParameter(name, defaultValue) {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  var results = regex.exec(location.search);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

function getGAVisitorId() {
  var visitorId;
  if (window.ga && ga.getAll) {
    var trackers = ga.getAll();
    if (trackers.length > 0) {
      visitorId = trackers[0].get("clientId");
    }
  }
  return visitorId;
}